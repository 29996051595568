import { render, staticRenderFns } from "./News.vue?vue&type=template&id=46cb7161&"
import script from "./News.vue?vue&type=script&lang=js&"
export * from "./News.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/docker/volumes/gitlab-runner-builds/_data/0/ochkarik/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46cb7161')) {
      api.createRecord('46cb7161', component.options)
    } else {
      api.reload('46cb7161', component.options)
    }
    module.hot.accept("./News.vue?vue&type=template&id=46cb7161&", function () {
      api.rerender('46cb7161', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend-new/apps/main-page/components/BlogNews/News.vue"
export default component.exports