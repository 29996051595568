import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5c535071&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/docker/volumes/gitlab-runner-builds/_data/0/ochkarik/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c535071')) {
      api.createRecord('5c535071', component.options)
    } else {
      api.reload('5c535071', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=5c535071&", function () {
      api.rerender('5c535071', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend-new/apps/main-page/components/Catalog/PromoSlider/index.vue"
export default component.exports